import React from "react"
import Text from "../../atoms/text"
import PropertyCard from "../../molecules/property-card"

import "./results-list.scss"

function ResultsList(props) {
  const propertyCards = props.properties.map(property => (
    <PropertyCard key={property._id} {...property} />
  ))

  return (
    <div className="results_list">
      <div className="results_list__header">
        <Text
          text="Amazing places in"
          font="font_style__heading-5"
          color="color__blue-3"
        ></Text>
        <Text text={props.whereTo} font="font_style__heading-2"></Text>
        <Text
          text={props.properties.length + " results"}
          color="color__slate-gray"
        ></Text>
      </div>
      <div className="results_list__content">{propertyCards}</div>
    </div>
  )
}
export default ResultsList
