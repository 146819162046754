//https://my.matterport.com/show/?m=3abEibxujEn&play=1&qs=1&hr=0&help=0&guides=0&mls=2&tourcta=0&f=0&ts=1
import React from "react"

import "./no-results.scss"

function NoResults(props) {
  return (
    <div class="no-results">
      <a class="no-results__logo" href="/">
        <img
          className="no-results__logo_image"
          src="/images/navigaze.png"
          alt="Navigaze"
        ></img>
      </a>
      <div class="no-results__status">
        <p>
          Unfortunately, there are no available properties matching your search
          criteria.
        </p>
        <p>Please change your dates or criteria and try again.</p>
      </div>
      <a class="no-results__button button button--main" href="/">
        Go To Homepage
      </a>
    </div>
  )
}
export default NoResults
