import React from "react"
import ClusteredMap from "../../atoms/clustered-map"

import "./results-map.scss"

function ResultsMap(props) {
  return (
    <div className="results_map">
      <ClusteredMap
        apiKey="AIzaSyCD6PZ0pld1Lu_-0VrNwWzMVt_fuL73KM8"
        mapTitle="Property"
        zoom={13}
        properties={props.properties}
        onMarkerClick={props.onMarkerClick}
      ></ClusteredMap>
    </div>
  )
}
export default ResultsMap
