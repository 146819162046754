import React from "react"
import {
  GoogleMap,
  LoadScriptNext,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api"
import { window } from "browser-monads"

import "./clustered-map.scss"

function ClusteredMap(props) {
  const styles = require("./MapStyles.json")
  const properties = props.properties

  const mapLoaded = map => {
    const bounds = new window.google.maps.LatLngBounds()

    properties.map(property => {
      return bounds.extend(
        new window.google.maps.LatLng(property.lat, property.lng)
      )
    })

    // Don't zoom in too far on only one marker and the properties are always in the middle
    var extendPoint1 = new window.google.maps.LatLng(
      bounds.getNorthEast().lat() + 0.0025,
      bounds.getNorthEast().lng() + 0.0025
    )
    var extendPoint2 = new window.google.maps.LatLng(
      bounds.getNorthEast().lat() - 0.0025,
      bounds.getNorthEast().lng() - 0.0025
    )
    bounds.extend(extendPoint1)
    bounds.extend(extendPoint2)

    map.fitBounds(bounds)
  }

  const LoadMap = () => (
    <LoadScriptNext
      googleMapsApiKey={props.apiKey}
      onError={error => {
        console.log(error)
      }}
    >
      <GoogleMap
        mapContainerClassName="clustered_map"
        options={{
          streetViewControl: false,
          styles: styles,
          clickableIcons: false,
          mapTypeId: "satellite",
        }}
        onLoad={mapLoaded}
      >
        <MarkerClusterer styles={MarkerClusterStyles}>
          {clusterer =>
            properties.map(property => (
              <Marker
                onClick={() => props.onMarkerClick(property.propertyCardId)}
                key={property._id}
                position={property}
                clusterer={clusterer}
                icon={mapImage}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScriptNext>
  )

  return properties === [] ? "" : <LoadMap />
}

const mapImage = {
  url: "/images/house_icon.png",
  scaledSize: {
    width: 40,
    height: 40,
  },
}

const MarkerClusterCss = {
  textColor: "white",
  textSize: "15",
  fontWeight: "bold",
}

const MarkerClusterStyles = [
  {
    height: 30,
    width: 35,
    ...MarkerClusterCss,
  },
  {
    height: 35,
    width: 40,
    ...MarkerClusterCss,
  },
  {
    height: 40,
    width: 45,
    ...MarkerClusterCss,
  },
]

export default ClusteredMap
