import React from "react"
import Carousel from "../../atoms/carousel"
import Text from "../../atoms/text"

import "./property-card.scss"

function PropertyCard(props) {
  const propertyGuests =
    props.Sleeps !== 0 ? (
      <Text
        text={props.Sleeps + " guests"}
        icon="people_alt"
        font="font_style__small_details"
      ></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  const propertyBeds =
    props.Bedrooms !== 0 ? (
      <Text
        text={props.Bedrooms + " bedrooms"}
        icon="king_bed"
        font="font_style__small_details"
      ></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  const propertyBaths =
    props.Bathrooms !== 0 ? (
      <Text
        text={props.Bathrooms + " bathrooms"}
        icon="bathtub"
        font="font_style__small_details"
      ></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  const propertyCost =
    props.calcPrice.totalPrice !== undefined ? (
      <div>
        <div className="font_style__heading-5 color__slate-gray">
          Suggested price
        </div>
        <div className="property_card__cost">
          <div className="property_card__price">{`USD ${props.calcPrice.totalPrice}`}</div>
          <div className="property_card__stay">{`for ${props.calcPrice.nights} nights`}</div>
        </div>
      </div>
    ) : (
      <div>
        <div className="font_style__heading-5 color__slate-gray">
          Starting at
        </div>
        <div className="property_card__cost">
          <div className="property_card__price">{`USD ${props.calcPrice.averagePrice}`}</div>
          <div className="property_card__stay">{`per night`}</div>
        </div>
      </div>
    )

  const urlDates =
    props.checkIn !== undefined && props.checkOut !== undefined
      ? `?checkIn=${props.checkIn}&checkOut=${props.checkOut}`
      : ``

  return (
    <a
      href={`${props.UnitUrl}/${urlDates}`}
      target="_blank"
      rel="noopener noreferrer"
      className="property_card"
      id={props.propertyCardId}
    >
      <Carousel Images={props.Images} />
      <div className="property_card__info">
        <div className="property_card__name">{props.PropertyName}</div>

        {propertyCost}

        <div className="property_card__details">
          {propertyGuests}
          {propertyBeds}
          {propertyBaths}
        </div>
      </div>
    </a>
  )
}

export default PropertyCard
