import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { parseISO } from "date-fns"
import * as queryString from "query-string"
import "../../translations/i18n"

import Services from "../../setup/services/services"
import PropertyHelpers from "../../setup/utils/property-helper"

import Modal from "../../components/atoms/modal"
import NavBar from "../../components/organisms/navbar"
import ContactForm from "../../components/organisms/contact-form"
import ListHomeForm from "../../components/organisms/list-home-form"
import ResultsList from "../../components/organisms/results-list"
import ResultsMap from "../../components/organisms/results-map"
import Blank from "../../components/organisms/blank"
import NoResults from "../../components/organisms/no-results"
import HomeBackground from "../../components/organisms/home-background"
// import Footer from "../components/organisms/Footer"

import "./search.scss"

function Search(props) {
  // Init states for list modal
  const [listState, setListState] = useState(false)
  const showListModal = () => setListState(true)
  const hideListModal = () => setListState(false)

  // Init states for contact modal
  const [contactState, setContactState] = useState(false)
  const showContactModal = () => setContactState(true)
  const hideContactModal = () => setContactState(false)

  const [properties, setProperties] = useState([])
  const [results, setResults] = useState(null)
  const [placeName, setPlaceName] = useState("")

  // Set the links to open a form in a modal
  const link = [
    { id: "listHome", modal: showListModal },
    { id: "contactUs", modal: showContactModal },
  ]
  useEffect(() => {
    const { whereTo, checkIn, checkOut, adults, kids } = queryString.parse(
      props.location.search
    )

    Services.get(
      `/properties/${whereTo}/${checkIn}/${checkOut}/${adults}/${kids}`
    ).then(response => {
      if (response !== undefined && response.data.properties.length > 0) {
        whereTo === "all"
          ? setPlaceName("México")
          : setPlaceName(response.data.properties[0].PlaceId.Name)

        const properties = response.data.properties.map(property => {
          property.PricedDates = PropertyHelpers.defaultPrices(
            property.Price,
            property.PricedDates
          )

          return {
            propertyCardId: `property_card_${property._id}`,
            img: property.AllImageThumbnail,
            Images: property.VRFrontThumbnail,
            lng: property.MapLocation.coordinates[0],
            lat: property.MapLocation.coordinates[1],
            calcPrice: PropertyHelpers.calcPrices(
              parseISO(checkIn),
              parseISO(checkOut),
              property.PricedDates,
              property.Price
            ),
            checkIn,
            checkOut,
            ...property,
          }
        })

        setProperties(properties)
        setResults(properties.length)
      } else {
        setResults(0)
      }
    }, [])
  }, [props.location.search])

  const url =
    typeof window !== "undefined"
      ? window.location.href
      : "https://www.navigaze.com/"

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Navigaze. Vacation Rentals. Punta Mita & Puerto Vallarta Mexico.
        </title>
        <meta
          name="title"
          content={`Navigaze. Vacation Rentals. Punta Mita & Puerto Vallarta Mexico.`}
        />
        <meta
          name="description"
          content="Find your dream holiday. Explore cities & locations in 360 degrees. All properties have virtual tours. No surprises. No extra fees."
        />
        <meta
          property="og:title"
          content={`Navigaze. Vacation Rentals. Punta Mita & Puerto Vallarta Mexico.`}
        />
        <meta
          property="og:description"
          content="Find your dream holiday. Explore cities & locations in 360 degrees. All properties have virtual tours. No surprises. No extra fees."
        />
        <meta
          property="og:image"
          content="https://www.navigaze.com/images/navigaze.png"
        />
        <meta property="og:url" content={url} />
      </Helmet>
      <NavBar links={link} sticky={true} />

      {results === null ? (
        <Blank></Blank>
      ) : results === 0 ? (
        <React.Fragment>
          <HomeBackground />
          <NoResults></NoResults>
        </React.Fragment>
      ) : (
        <div className="search__content">
          <ResultsMap
            properties={properties}
            onMarkerClick={onMarkerClick}
          ></ResultsMap>
          <ResultsList
            properties={properties}
            whereTo={placeName}
          ></ResultsList>
        </div>
      )}

      <Modal
        classId="contact_form_modal"
        show={contactState}
        children={
          contactState ? (
            <ContactForm handleClose={() => hideContactModal()} />
          ) : (
            ""
          )
        }
        handleClose={() => hideContactModal()}
      />
      <Modal
        classId="list_home_form_modal"
        show={listState}
        children={
          listState ? <ListHomeForm handleClose={() => hideListModal()} /> : ""
        }
        handleClose={() => hideListModal()}
      />
      {/* <Footer /> */}
    </React.Fragment>
  )
}

const onMarkerClick = propertyCardId => {
  const propertyCard = document.getElementById(propertyCardId)
  propertyCard.classList.add("property_card__focused")

  propertyCard.scrollIntoView({ block: "center", behavior: "smooth" })

  setTimeout(function () {
    propertyCard.classList.remove("property_card__focused")
  }, 1000)
}

export default Search
