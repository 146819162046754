import React from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./carousel.scss"

function Carousel(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const images = props.Images.slice(0, 6).map(image => {
    if (image.startsWith("/img/")) {
      return image.replace("/img/", "https://res.cloudinary.com/navigaze/image/fetch/f_auto,q_auto,c_fill,g_auto/https://www.navigaze.com/img/")
    }
    return image
  })

  const gallery = () => {
    return images
      ? images.slice(0, 6).map((image, index) => {
          return (
            <div key={image + index}>
              <div className="carousel__wrapper">
                <img src={image} alt={index} loading="lazy" />
              </div>
            </div>
          )
        })
      : null
  }
  return (
    <div className="carousel">
      <Slider {...settings}>{gallery()}</Slider>
    </div>
  )
}
export default Carousel
